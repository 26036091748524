'use strict';

const Swiper = require('swiper/swiper-bundle.min.js');

$(function () {
    $('.experience-commerce_layouts-navRow').each(function () {
        const $navTileBlock = this.querySelector('.row-links'); // vanilla js 'this'
        const $sliderSettings = $(this).find('.nav-slider-settings');
        const componentId = $sliderSettings.data('componentId');
        const smCarouselDots = $sliderSettings.data('smDots');
        const mdCarouselDots = $sliderSettings.data('mdDots');
        const lgCarouselDots = $sliderSettings.data('lgDots');
        const smCarouselArrows = $sliderSettings.data('smArrows');
        const mdCarouselArrows = $sliderSettings.data('mdArrows');
        const lgCarouselArrows = $sliderSettings.data('lgArrows');
        const smCarouselScroll = $sliderSettings.data('smScroll');
        const mdCarouselScroll = $sliderSettings.data('mdScroll');
        const lgCarouselScroll = $sliderSettings.data('lgScroll');

        /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "navRowCarousel" }]*/
        const navRowCarousel = new Swiper($navTileBlock, {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 'auto',
            centerInsufficientSlides: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                enabled: smCarouselDots,
                clickable: true
            },
            navigation: {
                nextEl: `.swiper-nav-next-${componentId}`,
                prevEl: `.swiper-nav-prev-${componentId}`,
                enabled: smCarouselArrows
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                enabled: smCarouselScroll,
                draggable: true,
                hide: false
            },
            breakpoints: {
                544: {
                    slidesPerView: 'auto',
                    freeMode: false,
                    pagination: {
                        enabled: smCarouselDots
                    },
                    navigation: {
                        enabled: smCarouselArrows
                    },
                    scrollbar: {
                        enabled: smCarouselScroll
                    }
                },
                769: {
                    slidesPerView: 'auto',
                    freeMode: false,
                    pagination: {
                        enabled: mdCarouselDots
                    },
                    navigation: {
                        enabled: mdCarouselArrows
                    },
                    scrollbar: {
                        enabled: mdCarouselScroll
                    }
                },
                1200: {
                    slidesPerView: 'auto',
                    freeMode: false,
                    pagination: {
                        enabled: lgCarouselDots
                    },
                    navigation: {
                        enabled: lgCarouselArrows
                    },
                    scrollbar: {
                        enabled: lgCarouselScroll
                    }
                }
            }
        });
    });
});
